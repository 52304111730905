
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Sell Requests</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm">
                <template v-slot:cell(friendly_id)="data">
                  <div style="min-width:9rem;"> {{data.item.friendly_id}}</div>
                </template>
                 <template v-slot:cell(email)="data">
                  <div>
                    <span> {{data.item.email}}</span>
                  </div>
                </template>
                <template v-slot:cell(mobile)="data">
                  <div>
                    <span> {{data.item.mobile}}</span>
                  </div>
                </template>
                <template v-slot:cell(device)="data">
                  <div v-if="data.item.device" style="min-width:6rem;"> {{  data.item.device.model }} </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div><span v-html="statusBadge(data.item.status)"> </span></div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <div style="min-width:6rem;"> {{$filters.date(data.item.created_at)}} </div>
                </template>
                <template v-slot:cell(action)="data">
                    <a :href="`/sell-requests/${data.item.id}`" class="">
                    view</a>
                </template>
            </b-table>
          </div>
          <div v-if="!pageData.data.length && !isLoading" class="fs-5 py-5 text-muted text-center">
            <p class="mb-0">There are no requests to show.</p>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="7" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from "@/components/IsLoading.vue"
import _ from 'lodash';

export default {
  name: "lease-list",
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      pageData: {
        data:[]
      },
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
      defaultStatus: null,
      statusOptions:[
        {value: 'pending', label: 'Pending'},
        {value: 'responded', label: 'Responded'},
        {value: null, label: 'View All'}
      ],
      columns: [
        {
          key: "friendly_id",
          label: "Request ID",
        },
          {
          key: "email",
          label: "Email",
        },
        {
          key: "mobile",
          label: "Mobile",
        },
        {
          key: "device",
          label: "Device",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        "action"
      ]
    }
  },
  watch: {
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/sell-requests?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
  },
  created(){
    this.fetchItems()
  },
}

</script>

